import { LoaderFunction, MetaFunction, json } from '@remix-run/node';
import { Link, useNavigate } from '@remix-run/react';
import { RiEmotionSadLine } from '@remixicon/react';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '~/components/empty-state';
import { LazyImage } from '~/components/lazy-image';
import { Button } from '~/components/ui/button';
import i18next from '~/i18next.server';

export const loader: LoaderFunction = async ({ request }) => {
  const locale = await i18next.getLocale(request);
  const t = await i18next.getFixedT(request, 'common');
  const title = t('labels.page404title');
  const description = t('labels.page404description');
  return json({ locale, title, description });
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: data?.title },
    { name: 'description', content: data?.description },
  ];
};

export default function FourOhFour() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <div>
      <nav className="fixed top-0 z-50 flex w-full items-center justify-center bg-white bg-gradient-to-r from-primary/5 px-6 py-3 shadow-sm">
        <Link to="/">
          <LazyImage src="/svg/logo.svg" alt="homy" />
        </Link>
      </nav>
      <div className="flex h-screen flex-col items-center justify-center">
        <div className="flex flex-col gap-6 px-10 sm:flex-row">
          <EmptyState className="m-auto h-full">
            <EmptyState.Icon
              scale={false}
              size={70}
              className="h-40 w-40 md:h-40 md:w-40"
            >
              <RiEmotionSadLine />
            </EmptyState.Icon>
            <EmptyState.Description className="text-sm">
              Error Code: 404
            </EmptyState.Description>
          </EmptyState>
          <div className="flex max-w-xl flex-col items-start justify-center">
            <p className="text-4xl font-bold text-homy-gray-light">
              {t('labels.page404Intro')}
            </p>
            <div className="flex flex-row items-center gap-1">
              <Button
                variant="ghost-primary"
                className="p-0 font-bold"
                onClick={goBack}
              >
                {t('labels.goBack')}
              </Button>
              {t('labels.or')}
              <Button
                variant="ghost-primary"
                className="p-0 font-bold"
                onClick={() => navigate('/')}
              >
                {t('labels.backHome')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
